import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { CONFIGURATION_TYPES } from 'state-domains/constants';

import { buildConfigurationReorderCoordinatesTableUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const genericReorder = (
    payload: any,
    params?: any[],
    type = CONFIGURATION_TYPES.NONE,
    url = '',
): Observable<any> => {
    const urlMap: any = {
        [CONFIGURATION_TYPES.GRIDS]: buildConfigurationReorderCoordinatesTableUrl,
    };

    const finalUrl = url || urlMap[type](...(params ?? []));

    return sendRequestWithXSRFToken(finalUrl, payload, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => observableOf(convertToCamel(response))),
    );
};
